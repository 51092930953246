import { Key, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, DatePicker, Table, Tag, Tabs, Card, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { AgreementBillStatus, AgreementBillType } from '@src/types/agreementBills'
import { ColumnProps } from 'antd/lib/table'
import { AgreementInvoicesStatus, AgreementInvoicesType } from '@src/types/agreementInvoices'
import { TransportationBillType } from '@src/types/transportationBills'
import { BillsTypeEnum } from '@src/types/settings'
import { Filters } from '@src/components/Table/Filters/Filters'
import { TableFiltersType } from '@src/types/tableFilters'
import { InvoicesType } from '@src/types/invoices'
import { useHistory } from 'react-router-dom'
import { ConfirmEmail } from '@src/components/ConfirmEmail/ConfirmEmail'
import { companiesApi } from '@src/store/services/companies-service'
import { accountingApi } from '@src/store/services/accounting-service'
import { carsApi } from '@src/store/services/cars-service'
import { CompanyType } from '@src/types/company'
import { formatPrice } from '@src/lib/formatPrice'
import moment from 'moment'
import { Preloader } from '@src/components/Preloader/Preloader'
import { isSelectionText } from '@src/lib/utils'
import { addQuery } from '@src/lib/route'
import useQuery from '@src/hooks/useQuery'
import style from './accounting.module.scss'

const statusColor: any = {
  1: 'blue',
  2: 'green',
  3: 'red',
}

export const Accounting = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [getBillsTrigger, { data: bills, isFetching: isLoadingGetBills }] = accountingApi.useLazyGetBillsQuery()
  const [getBillsRevenueTrigger, { data: billsRevenue, isLoading: isLoadingGetBillsRevenue }] = accountingApi.useLazyGetBillsRevenueQuery()
  const { data: companiesList } = companiesApi.useGetSelectCompaniesQuery()
  const { data: customersList } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Customer)
  const { data: makesList } = carsApi.useGetCarsMakesQuery()
  const { data: modelsList } = carsApi.useGetCarsModelsQuery()
  const selectedTabQuery = useQuery('selectedTab')
  const dateFromQuery = useQuery('dateFrom')
  const dateToQuery = useQuery('dateTo')
  const [dates, setDates] = useState<any>(dateFromQuery && dateToQuery ? [moment(dateFromQuery), moment(dateToQuery)] : [moment().add(-1, 'month'), moment()])
  const [isOpenSendEmail, setIsOpenSendEmail] = useState(false)
  const [selectedRow, setSelectedRow] = useState<number[]>([])
  const [selectedTab, setSelectedTab] = useState<BillsTypeEnum>(selectedTabQuery as BillsTypeEnum || BillsTypeEnum.AgreementInvoices)
  const [sendEmailAccountant, { isLoading: isLoadingSendBills }] = accountingApi.useSendEmailAccountantMutation()

  useEffect(() => {
    history.push({
      search: addQuery({ selectedTab, dateFrom: dates[0].format(), dateTo: dates[1].format() }),
    })
  }, [dates, history, selectedTab])

  const onCellAgreementBill = useCallback((record: AgreementBillType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/agreement-bills/edit/${record.id}`)
      }
    },
  }), [history])

  const onCellAgreementInvoice = useCallback((record: AgreementInvoicesType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/agreement-invoices/edit/${record.id}`)
      }
    },
  }), [history])

  const onCellTransportationBill = useCallback((record: TransportationBillType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/transportation-bills/edit/${record.id}`)
      }
    },
  }), [history])

  const onCellBusinessInvoices = useCallback((record: InvoicesType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/invoices/edit/${record.id}`)
      }
    },
  }), [history])

  const onCellMailHandler = useCallback((record: any) => ({
    onClick: () => {
      if (!isSelectionText()) {
        setSelectedRow([record.id])
        setIsOpenSendEmail(true)
      }
    },
  }), [])

  const agreementBillColumns = useMemo<ColumnProps<AgreementBillType>[]>(() => [
    {
      title: t('agreementBills.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (index + 1),
      onCell: onCellAgreementBill,
    },
    {
      title: t('agreementBills.columns.date'),
      dataIndex: 'agreement',
      key: 'dateEnd',
      render: (agreement) => `${agreement.date_end}`,
      onCell: onCellAgreementBill,
    },
    {
      title: t('agreementBills.columns.customer'),
      dataIndex: 'company',
      key: 'company',
      width: 200,
      render: (company) => company.name,
      onCell: onCellAgreementBill,
    },
    {
      title: t('agreementsCreate.car'),
      dataIndex: 'agreement',
      key: 'car',
      width: 200,
      render: (agreement) => `${agreement.car.make} ${agreement.car.model} (${agreement.car.serial_number})`,
      onCell: onCellAgreementBill,
    },
    {
      title: t('agreementBills.columns.billNumber'),
      dataIndex: 'bill_number',
      key: 'bill_number',
      onCell: onCellAgreementBill,
    },
    {
      title: t('agreementBills.columns.price'),
      dataIndex: 'price',
      key: 'price',
      render: (price) => `${price}€`,
      onCell: onCellAgreementBill,
    },
    {
      title: t('agreementBills.columns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        switch (status) {
          case AgreementBillStatus.notProcessed:
            return <Tag color="blue">{t(`agreementBills.status.${AgreementBillStatus.notProcessed}`)}</Tag>
          case AgreementBillStatus.processed:
            return <Tag color="green">{t(`agreementBills.status.${AgreementBillStatus.processed}`)}</Tag>
          case AgreementBillStatus.archived:
            return <Tag color="green">{t(`agreementBills.status.${AgreementBillStatus.archived}`)}</Tag>
          default:
            return ''
        }
      },
      onCell: onCellAgreementBill,
    },
    {
      title: t('agreementBills.columns.sentAccountant'),
      dataIndex: 'isSentAccountant',
      key: 'isSentAccountant',
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: onCellAgreementBill,
    },
    {
      title: t('agreementBills.columns.sentCustomer'),
      dataIndex: 'isSentCustomer',
      key: 'isSentCustomer',
      className: style.sendCustomer,
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: onCellMailHandler,
    },
  ], [onCellAgreementBill, onCellMailHandler, t])

  const agreementInvoiceColumns = useMemo<ColumnProps<AgreementInvoicesType>[]>(() => [
    {
      title: t('agreementInvoices.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (index + 1),
      onCell: onCellAgreementInvoice,
    },
    {
      title: t('agreementInvoices.columns.dateStart'),
      dataIndex: 'date_start',
      key: 'date_start',
      onCell: onCellAgreementInvoice,
    },
    {
      title: t('agreementInvoices.columns.dateEnd'),
      dataIndex: 'date_end',
      key: 'date_end',
      onCell: onCellAgreementInvoice,
    },
    {
      title: t('agreementInvoices.columns.customer'),
      dataIndex: 'company',
      key: 'company',
      width: 200,
      render: (company) => company.name,
      onCell: onCellAgreementInvoice,
    },
    {
      title: t('agreementInvoices.columns.car'),
      dataIndex: 'agreement',
      key: 'car',
      width: 200,
      render: (agreement) => `${agreement.car.make} ${agreement.car.model} (${agreement.car.serial_number})`,
      onCell: onCellAgreementInvoice,
    },
    {
      title: t('agreementInvoices.columns.number'),
      dataIndex: 'bill_number',
      key: 'bill_number',
      onCell: onCellAgreementInvoice,
    },
    {
      title: t('agreementInvoices.columns.price'),
      dataIndex: 'price_month',
      key: 'price_month',
      render: (priceMonth) => `${priceMonth}€`,
      onCell: onCellAgreementInvoice,
    },
    {
      title: t('agreementInvoices.columns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        switch (status) {
          case AgreementInvoicesStatus.notProcessed:
            return <Tag color="blue">{t(`agreementInvoices.status.${AgreementInvoicesStatus.notProcessed}`)}</Tag>
          case AgreementInvoicesStatus.processed:
            return <Tag color="green">{t(`agreementInvoices.status.${AgreementInvoicesStatus.processed}`)}</Tag>
          case AgreementInvoicesStatus.archived:
            return <Tag color="green">{t(`agreementInvoices.status.${AgreementInvoicesStatus.archived}`)}</Tag>
          default:
            return ''
        }
      },
      onCell: onCellAgreementInvoice,
    },
    {
      title: t('agreementInvoices.columns.sentAccountant'),
      dataIndex: 'isSentAccountant',
      key: 'isSentAccountant',
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: onCellAgreementInvoice,
    },
    {
      title: t('agreementInvoices.columns.sentCustomer'),
      dataIndex: 'isSentCustomer',
      key: 'isSentCustomer',
      className: style.sendCustomer,
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: onCellMailHandler,
    },
  ], [onCellAgreementInvoice, onCellMailHandler, t])

  const transportationBillColumns = useMemo<ColumnProps<TransportationBillType>[]>(() => [
    {
      title: t('transportationBills.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (index + 1),
      onCell: onCellTransportationBill,
    },
    {
      title: t('transportationBills.columns.payBy'),
      dataIndex: 'pay_by',
      key: 'pay_by',
      onCell: onCellTransportationBill,
    },
    {
      title: t('transportationBills.columns.dateClosed'),
      dataIndex: 'date_closed',
      key: 'date_closed',
      onCell: onCellTransportationBill,
    },
    {
      title: t('transportationBills.columns.dateId'),
      dataIndex: 'transportation',
      key: 'date',
      render: (transportation) => transportation.date,
      onCell: onCellTransportationBill,
    },
    {
      title: t('transportation.columns.to'),
      dataIndex: 'transportation',
      key: 'name',
      width: 150,
      render: (transportation) => transportation.to.name,
      onCell: onCellTransportationBill,
    },
    {
      title: t('transportation.columns.addressFrom'),
      dataIndex: 'transportation',
      key: 'addressFrom',
      width: 200,
      render: (transportation) => transportation.address_from,
      onCell: onCellTransportationBill,
    },
    {
      title: t('transportation.columns.addressTo'),
      dataIndex: 'transportation',
      key: 'addressTo',
      width: 200,
      render: (transportation) => transportation.address_to,
      onCell: onCellTransportationBill,
    },
    {
      title: t('transportation.columns.billNumber'),
      dataIndex: 'bill_number',
      key: 'bill_number',
      onCell: onCellTransportationBill,
    },
    {
      title: t('transportation.columns.sum'),
      dataIndex: 'sum',
      key: 'sum',
      render: (sum) => `${sum}€`,
      onCell: onCellTransportationBill,
    },
    {
      title: t('transportationBills.columns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <Tag color={statusColor[status]}>{t(`transportationBills.status.${status}`)}</Tag>,
      onCell: onCellTransportationBill,
    },
    {
      title: t('transportationBills.columns.sentAccountant'),
      dataIndex: 'isSentAccountant',
      key: 'isSentAccountant',
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: onCellTransportationBill,
    },
    {
      title: t('transportationBills.columns.sentCustomer'),
      dataIndex: 'isSentCustomer',
      key: 'isSentCustomer',
      className: style.sendCustomer,
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: onCellMailHandler,
    },
  ], [onCellMailHandler, onCellTransportationBill, t])

  const businessInvoicesColumns = useMemo<ColumnProps<InvoicesType>[]>(() => [
    {
      title: t('invoices.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (index + 1),
      onCell: onCellBusinessInvoices,
    },
    {
      title: t('invoices.columns.payBy'),
      dataIndex: 'pay_by',
      key: 'pay_by',
      onCell: onCellBusinessInvoices,
    },
    {
      title: t('invoices.columns.dateCreated'),
      dataIndex: 'date_closed',
      key: 'date_closed',
      onCell: onCellBusinessInvoices,
    },
    {
      title: t('invoices.columns.company'),
      dataIndex: 'company',
      key: 'company',
      render: (company) => company.name,
      onCell: onCellBusinessInvoices,
    },
    {
      title: t('invoices.columns.billNumber'),
      dataIndex: 'bill_number',
      key: 'bill_number',
      onCell: onCellBusinessInvoices,
    },
    {
      title: t('invoices.columns.sum'),
      dataIndex: 'sum',
      key: 'sum',
      render: (sum) => `${sum}€`,
      onCell: onCellBusinessInvoices,
    },
    {
      title: t('invoices.columns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={statusColor[status]}>{t(`invoices.status.${status}`)}</Tag>
      ),
      onCell: onCellBusinessInvoices,
    },
    {
      title: t('invoices.columns.sentAccountant'),
      dataIndex: 'isSentAccountant',
      key: 'isSentAccountant',
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: onCellBusinessInvoices,
    },
    {
      title: t('invoices.columns.sentCustomer'),
      dataIndex: 'isSentCustomer',
      key: 'isSentCustomer',
      className: style.sendCustomer,
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: onCellMailHandler,
    },
  ], [onCellBusinessInvoices, onCellMailHandler, t])

  const onChangeDates = (newDates: any) => {
    setDates(newDates)
    setSelectedRow([])
  }

  const getAccounting = () => {
    getBillsTrigger({ dates, type: selectedTab })
    getBillsRevenueTrigger({ dates, type: selectedTab })
  }

  useEffect(() => {
    getAccounting()// eslint-disable-next-line
  }, [])

  const sendAccounting = async (email: string) => {
    const result = await sendEmailAccountant({ ids: selectedRow, email, type: selectedTab })

    if ('data' in result) {
      setIsOpenSendEmail(false)
      notification.success({
        message: result.data,
      })
    }
  }

  const handleFilters = (e: Array<{ columns: string, value: any }>) => {
    getBillsTrigger({ dates, type: selectedTab, filters: { filters: e } })
  }

  const filtersListAgreementBills = useMemo<TableFiltersType>(() => [
    {
      type: 'select',
      dataIndex: 'customer_id',
      placeholder: t('agreementBills.columns.customer'),
      width: '200px',
      options: customersList?.map((item) => ({ value: item.id, label: item.name })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'make',
      placeholder: t('agreementBills.columns.make'),
      width: '200px',
      options: makesList?.map((item) => ({ value: item, label: item })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'model',
      placeholder: t('agreementBills.columns.model'),
      width: '200px',
      options: modelsList?.map((item) => ({ value: item, label: item })) || [],
      showSearch: true,
    },
    {
      type: 'input',
      dataIndex: 'serial_number',
      placeholder: t('agreementBills.columns.serialNumber'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'bill_number',
      placeholder: t('agreementBills.columns.billNumber'),
      width: '200px',
    },
    {
      type: 'select',
      dataIndex: 'isSentAccountant',
      placeholder: t('agreementBills.columns.sentAccountant'),
      width: '200px',
      options: [
        {
          value: '0',
          label: t('sendStatus.0'),
        },
        {
          value: '1',
          label: t('sendStatus.1'),
        },
      ],
    },
    {
      type: 'select',
      dataIndex: 'isSentCustomer',
      placeholder: t('agreementBills.columns.sentCustomer'),
      width: '200px',
      options: [
        {
          value: '0',
          label: t('sendStatus.0'),
        },
        {
          value: '1',
          label: t('sendStatus.1'),
        },
      ],
    },
  ], [customersList, makesList, modelsList, t])

  const filtersListAgreementInvoices = useMemo<TableFiltersType>(() => [
    {
      type: 'select',
      dataIndex: 'customer_id',
      placeholder: t('agreementInvoices.columns.customer'),
      width: '200px',
      options: customersList?.map((item) => ({ value: item.id, label: item.name })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'make',
      placeholder: t('agreementBills.columns.make'),
      width: '200px',
      options: makesList?.map((item) => ({ value: item, label: item })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'model',
      placeholder: t('agreementBills.columns.model'),
      width: '200px',
      options: modelsList?.map((item) => ({ value: item, label: item })) || [],
      showSearch: true,
    },
    {
      type: 'input',
      dataIndex: 'serial_number',
      placeholder: t('agreementBills.columns.serialNumber'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'bill_number',
      placeholder: t('agreementInvoices.columns.number'),
      width: '200px',
    },
    {
      type: 'select',
      dataIndex: 'isSentAccountant',
      placeholder: t('agreementInvoices.columns.sentAccountant'),
      width: '200px',
      options: [
        {
          value: '0',
          label: t('sendStatus.0'),
        },
        {
          value: '1',
          label: t('sendStatus.1'),
        },
      ],
    },
    {
      type: 'select',
      dataIndex: 'isSentCustomer',
      placeholder: t('agreementInvoices.columns.sentCustomer'),
      width: '200px',
      options: [
        {
          value: '0',
          label: t('sendStatus.0'),
        },
        {
          value: '1',
          label: t('sendStatus.1'),
        },
      ],
    },
  ], [customersList, makesList, modelsList, t])

  const filtersListTransportationBills = useMemo<TableFiltersType>(() => [
    {
      type: 'input',
      dataIndex: 'address_from',
      placeholder: t('transportationBills.columns.addressFrom'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'address_to',
      placeholder: t('transportationBills.columns.addressTo'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'bill_number',
      placeholder: t('transportation.columns.billNumber'),
      width: '200px',
    },
    {
      type: 'select',
      dataIndex: 'to_id',
      placeholder: t('transportation.columns.to'),
      width: '200px',
      options: companiesList?.map((item) => ({ value: item.id, label: item.name })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'isSentAccountant',
      placeholder: t('transportationBills.columns.sentAccountant'),
      width: '200px',
      options: [
        {
          value: '0',
          label: t('sendStatus.0'),
        },
        {
          value: '1',
          label: t('sendStatus.1'),
        },
      ],
    },
    {
      type: 'select',
      dataIndex: 'isSentCustomer',
      placeholder: t('transportationBills.columns.sentCustomer'),
      width: '200px',
      options: [
        {
          value: '0',
          label: t('sendStatus.0'),
        },
        {
          value: '1',
          label: t('sendStatus.1'),
        },
      ],
    },
  ], [t, companiesList])

  const filtersListBusinessInvoices = useMemo<TableFiltersType>(() => [
    {
      type: 'input',
      dataIndex: 'company',
      placeholder: t('invoices.columns.company'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'bill_number',
      placeholder: t('invoices.columns.billNumber'),
      width: '200px',
    },
    {
      type: 'select',
      dataIndex: 'isSentAccountant',
      placeholder: t('invoices.columns.sentAccountant'),
      width: '200px',
      options: [
        {
          value: '0',
          label: t('sendStatus.0'),
        },
        {
          value: '1',
          label: t('sendStatus.1'),
        },
      ],
    },
    {
      type: 'select',
      dataIndex: 'isSentCustomer',
      placeholder: t('invoices.columns.sentCustomer'),
      width: '200px',
      options: [
        {
          value: '0',
          label: t('sendStatus.0'),
        },
        {
          value: '1',
          label: t('sendStatus.1'),
        },
      ],
    },
  ], [t])

  const onChangeTab = async (tab: any) => {
    getBillsTrigger({ dates, type: tab })
    setSelectedTab(tab)
    setSelectedRow([])
  }

  return (
    <div>
      <div>
        <h1>{t('accounting.title')}</h1>
      </div>
      <div>
        <div className={style.flex}>
          <div>
            <DatePicker.RangePicker value={dates} style={{ width: 300 }} onChange={onChangeDates} />
          </div>
          <Button loading={isLoadingGetBills} onClick={getAccounting} style={{ margin: '16px 0' }}>
            {t('accounting.get')}
          </Button>
        </div>
      </div>
      <Card
        title={t('accounting.totalSum')}
        size="small"
        bodyStyle={{ columns: '2' }}
        className={style.sum}
        loading={isLoadingGetBillsRevenue}
      >
        <p>
          {t('accounting.agreementBill')}
          {': '}
          {formatPrice(billsRevenue?.sum?.agreement_bills || 0)}
        </p>
        <p>
          {t('accounting.agreementInvoices')}
          {': '}
          {formatPrice(billsRevenue?.sum?.agreement_invoices || 0)}
        </p>
        <p>
          {t('accounting.transportationBill')}
          {': '}
          {formatPrice(billsRevenue?.sum.transportation_bills || 0)}
        </p>
        <p>
          {t('accounting.businessInvoices')}
          {': '}
          {formatPrice(billsRevenue?.sum?.business_invoices || 0)}
        </p>
      </Card>

      {bills && (
        <>
          <div>
            <Tabs
              onChange={onChangeTab}
              activeKey={selectedTab}
              items={[
                {
                  key: BillsTypeEnum.AgreementInvoices,
                  label: t('accounting.agreementInvoices'),
                  children: (
                    <div>
                      {selectedTab === BillsTypeEnum.AgreementInvoices && (
                        <>
                          <div style={{ display: 'none' }}>
                            <Filters
                              filters={filtersListAgreementInvoices}
                              onChange={handleFilters}
                            />
                          </div>
                          <Preloader loading={isLoadingGetBills}>
                            <Table<AgreementInvoicesType>
                              components={{
                                body: {
                                  // eslint-disable-next-line max-len
                                  row: (props: any) => <tr {...props} style={{ background: bills.data.find((item) => item.id === props['data-row-key'])?.agreement.owner?.color }} />,
                                },
                              }}
                              rowSelection={{
                                selectedRowKeys: selectedRow,
                                type: 'checkbox',
                                onChange: (newSelectedRow: Key[]) => {
                                  setSelectedRow(newSelectedRow as any)
                                },
                              }}
                              dataSource={bills.data as AgreementInvoicesType[]}
                              columns={agreementInvoiceColumns}
                              rowKey="id"
                              scroll={{ x: 'max-content' }}
                              pagination={false}
                            />
                          </Preloader>
                        </>
                      )}
                    </div>
                  ),
                },
                {
                  key: BillsTypeEnum.AgreementBill,
                  label: t('accounting.agreementBill'),
                  children: (
                    <div>
                      {selectedTab === BillsTypeEnum.AgreementBill && (
                        <>
                          <div style={{ display: 'none' }}>
                            <Filters
                              filters={filtersListAgreementBills}
                              onChange={handleFilters}
                            />
                          </div>
                          <Preloader loading={isLoadingGetBills}>
                            <Table<AgreementBillType>
                              components={{
                                body: {
                                  // eslint-disable-next-line max-len
                                  row: (props: any) => <tr {...props} style={{ background: bills.data.find((item) => item.id === props['data-row-key'])?.agreement.owner.color }} />,
                                },
                              }}
                              rowSelection={{
                                selectedRowKeys: selectedRow,
                                type: 'checkbox',
                                onChange: (newSelectedRow: Key[]) => {
                                  setSelectedRow(newSelectedRow as any)
                                },
                              }}
                              dataSource={bills.data as AgreementBillType[]}
                              columns={agreementBillColumns}
                              rowKey="id"
                              scroll={{ x: 'max-content' }}
                              pagination={false}
                            />
                          </Preloader>
                        </>
                      )}
                    </div>
                  ),
                },
                {
                  key: BillsTypeEnum.TransportationBill,
                  label: t('accounting.transportationBill'),
                  children: (
                    <div>
                      {selectedTab === BillsTypeEnum.TransportationBill && (
                        <>
                          <div style={{ display: 'none' }}>
                            <Filters
                              filters={filtersListTransportationBills}
                              onChange={handleFilters}
                            />
                          </div>
                          <Preloader loading={isLoadingGetBills}>
                            <Table<TransportationBillType>
                              components={{
                                body: {
                                  // eslint-disable-next-line max-len
                                  row: (props: any) => <tr {...props} style={{ background: bills.data.find((item) => item.id === props['data-row-key'])?.company?.color }} />,
                                },
                              }}
                              rowSelection={{
                                selectedRowKeys: selectedRow,
                                type: 'checkbox',
                                onChange: (newSelectedRow: Key[]) => {
                                  setSelectedRow(newSelectedRow as any)
                                },
                              }}
                              dataSource={bills.data as TransportationBillType[]}
                              columns={transportationBillColumns}
                              rowKey="id"
                              scroll={{ x: 'max-content' }}
                              pagination={false}
                            />
                          </Preloader>
                        </>
                      )}
                    </div>
                  ),
                },
                {
                  key: BillsTypeEnum.BusinessInvoices,
                  label: t('accounting.businessInvoices'),
                  children: (
                    <div>
                      {selectedTab === BillsTypeEnum.BusinessInvoices && (
                        <>
                          <div style={{ display: 'none' }}>
                            <Filters
                              filters={filtersListBusinessInvoices}
                              onChange={handleFilters}
                            />
                          </div>
                          <Preloader loading={isLoadingGetBills}>
                            <Table<InvoicesType>
                              components={{
                                body: {
                                  // eslint-disable-next-line max-len
                                  row: (props: any) => <tr {...props} style={{ background: bills.data.find((item) => item.id === props['data-row-key'])?.company.color }} />,
                                },
                              }}
                              rowSelection={{
                                selectedRowKeys: selectedRow,
                                type: 'checkbox',
                                onChange: (newSelectedRow: Key[]) => {
                                  setSelectedRow(newSelectedRow as any)
                                },
                              }}
                              dataSource={bills.data as InvoicesType[]}
                              columns={businessInvoicesColumns}
                              rowKey="id"
                              scroll={{ x: 'max-content' }}
                              pagination={false}
                            />
                          </Preloader>
                        </>
                      )}
                    </div>
                  ),
                },
              ]}
            />

          </div>
          <Button onClick={() => setIsOpenSendEmail(true)} style={{ margin: '16px 0' }}>
            {t('accounting.send')}
          </Button>
        </>
      )}
      <ConfirmEmail
        callback={sendAccounting}
        email={bills?.email || ''}
        isLoading={isLoadingSendBills}
        onClose={() => setIsOpenSendEmail(false)}
        isOpen={isOpenSendEmail}
      />
    </div>
  )
}
