import React, { useCallback } from 'react'
import type { MenuProps } from 'antd'
import { UserRoles } from '@src/types/users'
import {
  ApartmentOutlined,
  AuditOutlined,
  BankOutlined,
  BarChartOutlined,
  CarOutlined,
  CrownOutlined,
  DatabaseOutlined,
  DockerOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  FileSyncOutlined,
  FileTextOutlined, HomeOutlined,
  MailOutlined,
  PartitionOutlined,
  ProfileOutlined,
  ReconciliationOutlined,
  RobotOutlined,
  SettingOutlined,
  SignatureOutlined,
  SolutionOutlined,
  TagsOutlined,
  TeamOutlined,
  ToolOutlined,
  TruckOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { authApi } from '@src/store/services/auth-service'

type MenuItem = Required<MenuProps>['items'][number];

export const useMenuList = () => {
  const { t } = useTranslation()
  const { data: user } = authApi.useGetMeQuery()

  const getCategory = useCallback((
    label: React.ReactNode,
    icon: React.ReactNode,
    children: MenuItem[],
    roles?: UserRoles[],
    type?: 'group',
  ): MenuItem => {
    if (roles?.length && !roles.includes(user!.role)) {
      return null
    }

    return {
      icon,
      children,
      label,
      type,
    } as MenuItem
  }, [user])

  const getItem = useCallback((
    label: string,
    link: string,
    icon: React.ReactNode,
    roles?: UserRoles[],
  ): MenuItem => {
    if (roles?.length && !roles.includes(user!.role)) {
      return null
    }

    return {
      icon,
      key: link,
      label: <NavLink to={link}>{label}</NavLink>,
    } as MenuItem
  }, [user])

  switch (user?.role) {
    case UserRoles.Admin:
      return [
        getItem(t('menu.owners'), '/owners', <CrownOutlined />),
        getItem(t('menu.customers'), '/customers', <TeamOutlined />),
        getItem(t('menu.carCategories'), '/car-categories', <TagsOutlined />),
        getItem(t('menu.cars'), '/cars', <CarOutlined />),
        getItem(t('menu.inventories'), '/inventories', <ApartmentOutlined />),
        getCategory(t('selectRole.rent'), <SolutionOutlined />, [
          getItem(t('menu.agreements'), '/agreements', <FileDoneOutlined />),
          getItem(t('menu.agreementInvoices'), '/agreement-invoices', <FileSyncOutlined />),
          getItem(t('menu.agreementBills'), '/agreement-bills', <FileProtectOutlined />),
        ]),
        getCategory(t('selectRole.transportation'), <TruckOutlined />, [
          getItem(t('menu.transportation'), '/transportation', <FileTextOutlined />),
          getItem(t('menu.transportationBills'), '/transportation-bills', <FileProtectOutlined />),
          getItem(t('menu.monthlyPayments'), '/monthly-payments', <FileSyncOutlined />),
          getItem(t('menu.trucks'), '/trucks', <DockerOutlined />),
        ]),
        getCategory(t('selectRole.business'), <BankOutlined />, [
          getItem(t('menu.debts'), '/debts', <SignatureOutlined />),
          getItem(t('menu.invoices'), '/invoices', <AuditOutlined />),
        ]),
        getCategory(t('selectRole.service'), <ToolOutlined />, [
          getItem(t('menu.serviceTemplates'), '/service-templates', <DatabaseOutlined />),
          getItem(t('menu.tasks'), '/tasks', <ProfileOutlined />),
          getItem(t('menu.stocks'), '/stocks', <HomeOutlined />),
          getItem(t('menu.details'), '/details', <PartitionOutlined />),
          getItem(t('menu.detailsInvoices'), '/detail-invoice', <FileTextOutlined />),
          getItem(t('menu.standardWork'), '/standard-work', <RobotOutlined />),
          getItem(t('menu.serviceInvoices'), '/service-invoices', <FileTextOutlined />),
        ]),
        getItem(t('menu.allInvoices'), '/all-invoices', <FileTextOutlined />),
        getItem(t('menu.accounting'), '/accounting', <ReconciliationOutlined />),
        getItem(t('menu.statistics'), '/statistics', <BarChartOutlined />),
        getCategory(t('selectRole.settings'), <SettingOutlined />, [
          getItem(t('menu.users'), '/users', <UsergroupAddOutlined />),
          getItem(t('menu.mailLogs'), '/mail-logs', <MailOutlined />),
        ]),
      ]
    case UserRoles.Driver:
      return [
        getItem(t('menu.owners'), '/owners', <CrownOutlined />),
        getItem(t('menu.customers'), '/customers', <TeamOutlined />),
        getItem(t('menu.transportation'), '/transportation', <FileTextOutlined />),
      ]
    case UserRoles.SalesManager:
      return []
    case UserRoles.ServiceManager:
      return [
        getItem(t('menu.customers'), '/customers', <TeamOutlined />),
        getItem(t('menu.tasks'), '/tasks', <ProfileOutlined />),
        getItem(t('menu.details'), '/details', <PartitionOutlined />),
        getItem(t('menu.detailsInvoices'), '/detail-invoice', <FileTextOutlined />),
        getItem(t('menu.serviceInvoices'), '/service-invoices', <FileTextOutlined />),
        getCategory(t('selectRole.settings'), <SettingOutlined />, [
          getItem(t('menu.serviceTemplates'), '/service-templates', <DatabaseOutlined />),
          getItem(t('menu.stocks'), '/stocks', <HomeOutlined />),
          getItem(t('menu.standardWork'), '/standard-work', <RobotOutlined />),
        ]),
      ]
    case UserRoles.Mechanic:
      return [
        getItem(t('menu.cars'), '/mechanic/cars', <CrownOutlined />),
        getItem(t('menu.tasks'), '/', <CrownOutlined />),
        getItem(t('menu.tasksArchive'), '/mechanic/tasks-archive', <TagsOutlined />),
        getItem(t('menu.myDetails'), '/mechanic/my-details', <TagsOutlined />),
        getItem(t('menu.detailTransfer'), '/mechanic/detail-transfer', <CarOutlined />),
        getItem(t('menu.getDetail'), '/mechanic/get-detail', <CarOutlined />),
      ]
    case UserRoles.StoreManager:
      return [
        getItem(t('menu.stocks'), '/stocks', <HomeOutlined />),
        getItem(t('menu.details'), '/details', <PartitionOutlined />),
        getItem(t('menu.detailsInvoices'), '/detail-invoice', <FileTextOutlined />),
      ]
    default:
      return []
  }
}
