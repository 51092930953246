import { useFormikContext } from 'formik'
import moment from 'moment'
import { usePrintableBlock } from '@src/hooks/usePrintableBlock'
import { Button } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { agreementsApi } from '@src/store/services/agreements-service'
import { useMemo } from 'react'
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import n2words from 'n2words'
import { customRound } from '@src/lib/converter'
import { companiesApi } from '@src/store/services/companies-service'

export const AgreementBillsDocument = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()
  const { data: agreement } = agreementsApi.useGetOneAgreementQuery(values.agreement_id)
  const { data: customer } = companiesApi.useGetOneCompanyQuery(values.company_id, { skip: !values.company_id })

  usePrintableBlock('#printable-block')

  const billNumber = useMemo(() => (
    values.bill_number || `${moment(agreement?.created_at).format('YYYY-MM-DD')}/${agreement?.id}`
  ), [agreement, values.bill_number])

  const datePayment = useMemo(() => {
    if (values.pay_by) {
      return moment(values.pay_by).format('YYYY-MM-DD')
    }
    return moment(agreement?.date_end).add(15, 'day').format('YYYY-MM-DD')
  }, [values, agreement])

  const netto = useMemo(() => (+values.price || 0).toFixed(2), [values])
  const pvm = useMemo(() => ((+values.price || 0) * 0.21).toFixed(2), [values])
  const total = useMemo(() => (+pvm + +netto).toFixed(2), [netto, pvm])

  const dateStart = useMemo(() => {
    if (agreement?.invoices?.length) {
      return moment(agreement?.date_end).startOf('month').format('YYYY-MM-DD')
    }
    return moment(agreement?.date_start).format('YYYY-MM-DD')
  }, [agreement])

  return (
    <>
      <div className="agreement_bills_document" id="printable-block">
        <p className="font-bold" style={{ textAlign: 'center', textTransform: 'uppercase' }}>
          {!!values.isCredit && 'KREDITINĖ'}
          {' '}
          PVM SĄSKAITA-FAKTŪRA
        </p>
        <p className="text" style={{ textAlign: 'center' }}>
          Serija SEN Nr.
          {' '}
          {billNumber}
        </p>
        <table style={{ fontSize: 13, tableLayout: 'fixed', width: '100%' }}>
          <tbody>
            <tr>
              <td valign="top" style={{ width: '50%', minWidth: '50%', verticalAlign: 'top' }}>
                Pardavėjas
                <br />
                <strong>{agreement?.owner.name}</strong>
                <br />
                {agreement?.owner.address}
                <br />
                Įmonės kodas
                {' '}
                {agreement?.owner.company_code}
                <br />
                PVM mok.kodas
                {' '}
                {agreement?.owner.vat_number}
                <br />
                A/s
                {' '}
                {agreement?.owner.account_number}
              </td>
              <td valign="top" style={{ width: '50%', minWidth: '50%', verticalAlign: 'top' }}>
                Pirkėjas
                <br />
                <strong>{customer?.name}</strong>
                <br />
                {customer?.address}
                <br />
                Įmonės kodas
                {' '}
                {customer?.company_code}
                <br />
                PVM mok.kodas
                {' '}
                {customer?.vat_number}
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text" style={{ textAlign: 'center' }}>
          Data:
          {' '}
          {moment(values.date_closed || undefined).format('YYYY-MM-DD')}
        </p>
        <p className="text">
          Apmokėti iki:
          {' '}
          {datePayment}
        </p>
        <table className="image-table" style={{ width: '100%', fontSize: 13 }}>
          <tr>
            <td className="image-row" style={{ width: '5%', fontWeight: 700, textAlign: 'center' }}>Nr.</td>
            <td className="image-row" style={{ width: '33.5%', fontWeight: 700 }}>Pavadinimas</td>
            <td className="image-row" style={{ width: '15%', fontWeight: 700, textAlign: 'center' }}>Mato vnt.</td>
            <td className="image-row" style={{ width: '10%', fontWeight: 700, textAlign: 'center' }}>Kiekis</td>
            <td className="image-row" style={{ width: '17.5%', fontWeight: 700, textAlign: 'center' }}>Kaina EUR</td>
            <td className="image-row" style={{ width: '19%', fontWeight: 700, textAlign: 'center' }}>Suma EUR</td>
          </tr>
          <tr>
            <td className="image-row" style={{ width: '5%', fontWeight: 400, textAlign: 'center' }}>1.</td>
            <td className="image-row" style={{ width: '33.5%', fontWeight: 400 }}>
              Nuoma
              {' '}
              {agreement?.car.make}
              {' '}
              {agreement?.car.model}
              <br />
              {agreement?.car.serial_number}
              <br />
              {dateStart}
              {' - '}
              {moment(agreement?.date_end).format('YYYY-MM-DD')}
            </td>
            <td className="image-row" style={{ width: '15%', fontWeight: 400, textAlign: 'center' }}>Mėnuo</td>
            <td className="image-row" style={{ width: '10%', fontWeight: 400, textAlign: 'center' }}>1</td>
            <td className="image-row" style={{ width: '17.5%', fontWeight: 400, textAlign: 'center' }}>
              {!!values.isCredit && '-'}
              {netto}
            </td>
            <td className="image-row" style={{ width: '19%', fontWeight: 400, textAlign: 'center' }}>
              {!!values.isCredit && '-'}
              {netto}
            </td>
          </tr>
        </table>
        <table style={{ fontSize: 13, width: '100%' }}>
          <tbody>
            <tr style={{ width: '100%' }}>
              <td style={{ width: '20%', color: '#ffffff' }}>.</td>
              <td style={{ width: '35%' }}>Iš viso:</td>
              <td style={{ width: '45%', textAlign: 'right' }}>
                {!!values.isCredit && '-'}
                {netto}
                {' '}
                EUR
              </td>
            </tr>
            <tr style={{ width: '100%' }}>
              <td style={{ width: '20%', color: '#ffffff' }}>.</td>
              <td style={{ width: '35%' }}>PVM 21%</td>
              <td style={{ width: '45%', textAlign: 'right' }}>
                {!!values.isCredit && '-'}
                {' '}
                {pvm}
                {' '}
                EUR
              </td>
            </tr>
            <tr style={{ width: '100%' }}>
              <td style={{ width: '20%', color: '#ffffff' }}>.</td>
              <td style={{ width: '35%' }}>Bendra suma</td>
              <td style={{ width: '45%', textAlign: 'right' }}>
                {!!values.isCredit && '-'}
                {' '}
                {total}
                {' '}
                EUR
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text">
          Iš viso su PVM:
          {' '}
          {n2words(customRound(+total), { lang: 'lt' })}
          {' '}
          EUR,
          {' '}
          {total.split('.')[1]}
          ct.
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '17%' }}>Sąskaitą išrašė:</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '83%', display: 'block', marginLeft: '17%' }}>
              <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            (vardas, pavardė, pareigos)
          </span>
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '20%' }}>Sąskaitą gavo:</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '80%', display: 'block', marginLeft: '20%' }}>
              <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            (vardas, pavardė, pareigos)
          </span>
        </p>
      </div>
      <div className="documentActions">
        <Button icon={<PrinterOutlined />} onClick={window.print}>{t('settings.print')}</Button>
      </div>
    </>
  )
}
