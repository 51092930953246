import { Agreement } from '@src/types/agreements'

export enum AgreementBillStatus {
  notProcessed = 1,
  processed = 2,
  archived = 3,
}

export type AgreementBillType = {
  price?: string
  bill_number?: string
  created_at: string
  text: string
  id: number
  isCredit: number
  pay_by?: string
  date_closed?: string
  company_id: number
  status: AgreementBillStatus
  agreement: Agreement
  agreement_id: number
  updated_at: string
  isSentAccountant: boolean
  isSentCustomer: boolean
}
