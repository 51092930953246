import { Storage } from './details'

export enum TaskStatusEnum {
  New = 'new',
  InProcess = 'in_process',
  Finished = 'finished',
  Signed = 'signed',
}

export type TaskImageType = {
  id: number
  image: string
  thumb: string
}

export type TaskCommentType = {
  id: number
  comment: string
  user_id: number
  images: Array<{
    id: number
    file: string
  }>
  user: {
    id: number
    name: string
    surname: string
  }
}

export type TaskType = {
  id: number
  template_id?: number
  number?: number
  name: string
  description: string
  status: TaskStatusEnum
  author_id: number
  client_id: number
  work_type_id: number
  car_id: number
  worker_ids: number
  planned_date_start: string
  planned_date_end: string
  date_start?: string
  date_end?: string
  from_service: boolean
  quiz?: string
  place: string
  work_duration_hours?: string
  work_duration_minutes?: string
  images: TaskImageType[]
  client: {
    id: number
    name: string
  }
  work_type: {
    id: number
    name: string
  }
  car: {
    id: number
    make: string
    model: string
    serial_number: string
  }
  author: {
    id: number
    name: string
    surname: string
  }
  workers: Array<{
    id: number
    name: string
    surname: string
  }>
  details: Array<{
    appointor?: Appointor
    count: number
    created_at: string
    from_service: number
    id: number
    name: string
    pivot?: {
      detail_id: number
      task_id: number
    }
    price: number
    sell_price: number
    spent_quantity: number
    quantity: number
    serial_number: string
    serial_numbers: [
      {
        created_at: string
        detail_id: number
        id: number
        serial_number: string
        updated_at: string
      }
    ]
    service_id: number | null
    short_name: string
    stock_area_id: number
    storage_count: number
    storages?: Storage[]
    total_count: number
    unit: string
    updated_at: string
    user_id: number
    vat: number
    vat_price: number
  }>
  comments: TaskCommentType[]
}

export type LastWorkTimelogType = {
  id: number
  ended?: string
  started: string
  task_id: number
  user_id: number
  mechanic: {
    id: number
    name: string
    surname: string
  }
}

export type EditTimelogRequest = {
  id: number
  started: string
  ended: string
}

export type TaskRouteType = {
  id: number
  task_id: number
  address_from: string
  address_to: string
  distance: number
  price: number
}

export type TaskSignRequest = {
  taskId: number
  body: {
    work_duration_hours: string
    work_duration_minutes: string
    date_start: string
    date_end: string
  }
}

type Appointor = {
    id: string
    name: string
    surname: string
}
