import { FC, useCallback, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import { DatePicker } from 'antd'
import { getObjectValue } from '@src/lib/utils'
import style from '@src/components/FieldFormik/FieldFormik.module.scss'
import cn from 'classnames'

type DatePickerFormikProps = {
  name: string
  placeholder?: string
  disabled?: boolean
  showTime?: boolean
  format?: string
}

export const DatePickerFormik: FC<DatePickerFormikProps> = ({
  name, placeholder, disabled, showTime, format,
}) => {
  const { setFieldValue, errors, touched, values } = useFormikContext<any>()
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  const value = useMemo(() => getObjectValue(name.split('.'), values), [name, values])
  const error = useMemo(() => getObjectValue(name.split('.'), errors), [name, errors])
  const isTouched = useMemo(() => getObjectValue(name.split('.'), touched), [name, touched])
  const isError = useMemo(() => error && isTouched, [error, isTouched])

  const handleChange = useCallback((vt: any) => {
    setFieldValue(name, vt)
  }, [name, setFieldValue])

  return (
    <div className={style.wrapper}>
      <DatePicker
        onChange={handleChange}
        size="large"
        placeholder=""
        value={value}
        style={{ width: '100%' }}
        format={format}
        disabled={disabled}
        showTime={showTime}
        className={cn({ [style.errorBorder]: isError })}
        onOpenChange={(open) => {
          setShowPlaceholder(!open)
        }}
      />
      {placeholder && showPlaceholder && (
        <div
          className={cn(style.placeholder, { [style.placeholder_active]: value })}
        >
          {placeholder}
        </div>
      )}
      {isError && <div className={style.error}>{error}</div>}
    </div>
  )
}
